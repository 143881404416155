<template>
    <front-layout>
        <div class="FaqArticle intro wrapper">
            <v-breadcrumbs :items="breadcrumb"/>

            <h1 v-text="faq.title" :class="{'text-center': faq.parent_id !== null}" class="mb-10"/>

            <v-row v-if="faq.text" :class="faq.parent_id !== null ? 'justify-center' : ''">
                <v-col v-if="faq.parent_id === null" md="4">
                    <p v-text="faq.text"/>
                </v-col>
                <v-col v-else md="7" v-html="faq.text" />
            </v-row>

            <v-row>
                <v-col v-for="(child, childIdx) in faq.children" :key="childIdx">
                    <h2 v-text="child.title"/>
                    <ul v-if="child.children">
                        <li v-for="(child, childChildIdx) in child.children" :key="childChildIdx">
                            <router-link :to="{name: 'faq.show', params: {id: child.id}}" v-text="child.title"/>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </div>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import Faq from "../../models/Faq";

    export default {
        name: "FaqArticle",
        components: {FrontLayout},
        props: {
            id: {
                required: true
            }
        },
        data() {
            return {
                breadcrumb: [],
                faq: {}
            }
        },
        mounted() {
            this.getFaqArticle()
        },
        methods: {
            async getFaqArticle() {
                this.faq = await Faq.find(this.id)
                this.breadcrumb = [];
                this.getBreadcrumb(this.faq);
                this.breadcrumb.push(
                    {
                        text: 'Help',
                        disabled: false,
                        href: this.$router.resolve({ name: 'faq' }).href,
                    },
                    {
                        text: 'Home',
                        disabled: false,
                        href: this.$router.resolve({ name: 'home' }).href,
                    },
                )
                this.breadcrumb.reverse();
            },

            getBreadcrumb(article) {
                const item = {
                    text: article.title,
                    disabled: false,
                    href: this.$router.resolve({name: 'faq.show', params: {id: article.id}}).href,
                };

                this.breadcrumb.push(item)

                if (article.parent_id !== null) {
                    return this.getBreadcrumb(article.parent)
                }
            }
        },
        watch: {
            id() {
                this.getFaqArticle()
            }
        }
    }
</script>

<style lang="scss" scoped>
.FaqArticle {

}
</style>
